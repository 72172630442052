import { useState } from "react";



function DialogueBox (){

    const [nextClicked, setNextClicked] = useState(false);
    const [dbVisible, setdbVisible] = useState (true);
    console.log(nextClicked)


    function handleClickBack(){
        setNextClicked(false)

    }

    function handleClickNext(){
        setNextClicked(true)
    }

    function handleClickConfirm() {
        setdbVisible(false)
    }
    
return(
    <>
    {dbVisible ? <>
        <div className="main-dialogue">


{nextClicked ? 
    <>
<h1>To Authenticate your NFT, visit OpenSea </h1>
<p>
a.	To authenticate your NFT, visit OpenSea.io and link your reliable wallet. <br/><br/>
b.	At your profile, click "Collections"; within the virtual quilt, you will discover your newly connected Youthful Repair NFT, which is proof of your involvement in this creative project. 

</p>
<div className="dialogue-buttons">
<div>
<button onClick={handleClickBack}>BACK</button>
</div>
<div>
<button onClick={handleClickConfirm}>CONFIRM</button>
</div>
</div>
</>
    :
    <>
    <h1>Journey at Youthful Repairs</h1>
<p>
a.	You can start your journey at Youthful Repair.io, by clicking the prominent "Mint" button. You'll be carried to an inner page, look for a reliable "Connect Wallet" button. <br/><br/> 
b.	Simply enter your wallet details to establish a secure connection. Automatically, you'll return to the home page with your wallet prepared. <br/><br/> 
c.	When you click the "Mint" button again, a MetaMask pop-up will appear, requesting your approval for a 0.5 ETH transaction on Youthful Repair.io. <br/><br/>
d.	If you want to confirm, your NFT journey, approve the transaction carefully after reviewing each information.

</p>
<div className="next-button">
<button onClick={handleClickNext}> NEXT</button>
</div>
</>


}
</div>

    
    </> :
    <>
    </>
        
    }

</>
);
}

export default DialogueBox