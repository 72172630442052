import Web3 from 'web3';
import Mint from './Mint';
import { useState, useRef, useEffect } from 'react';
import '../App.css';
import logo from '../Assets/LOGOHERE.png';
import ntfcenter from '../Assets/ntf-center.png';
import ntfleft from '../Assets/ntf-left.png';
import ntfright from '../Assets/ntf-right.png';
import roadmap from '../Assets/roadmap-image.png';
import instagram from '../Assets/Icon - Instagram.svg';
import facebook from '../Assets/Icon - Facebook.svg';
// import linkedin from '../Assets/Icon - Linkedin.svg';
import twitter from '../Assets/Icon - X.png';
import discord from '../Assets/Icon - Discord.svg';
import scrolltotop from '../Assets/scroll-to-top.png';
import { Link } from 'react-router-dom';


function Home() {





    const web3 = new Web3(window.ethereum);
    const [account, setAccount] = useState(localStorage.getItem('walletAddress'));
    const [roadmapHovered, setRoadmapHovered] = useState(false);
    const [whitepaperHovered, setWhitepaperHovered] = useState(false);

    //Connect Wallet
    const connectWallet = async () => {
        let provider = window.ethereum
        if (!provider) {
            alert('Metamask not installed');
        }
        else {
            provider.request({ method: 'eth_requestAccounts' }).then(accounts => {
                console.log(accounts);
                setAccount(accounts[0])
                localStorage.setItem('walletAddress', accounts[0])
                console.log()
            }).catch(error => {
                console.log(error)
            })
        }
    }
    const headerRef = useRef(null)
    const visionRef = useRef(null)
    const aboutRef = useRef(null)
    const roadmapRef = useRef(null)
    const teamRef = useRef(null)
    const scrollToTop = () => headerRef.current.scrollIntoView()
    const scrollVision = () => visionRef.current.scrollIntoView()
    const scrollAbout = () => aboutRef.current.scrollIntoView()
    const scrollRoadmap = () => roadmapRef.current.scrollIntoView()
    const scrollTeam = () => teamRef.current.scrollIntoView()

    useEffect(() => {
        // Update the document title using the browser API
        window.addEventListener('scroll', function () {
            var element = document.getElementById('backtop');

            if (window.pageYOffset === 0) {
                element.style.opacity = '0';
            } else {
                element.style.opacity = '1';
            }
        });

        var imageleft = document.getElementById('firstimg');
        var imagemiddle = document.getElementById('secondimg');
        var imageright = document.getElementById('thirdimg');

        if (imageleft) {
            imageleft.addEventListener('mouseenter', function () {
                imageleft.style.transform = 'scale(1.0)';
                imagemiddle.style.transform = 'scale(0.7)';
                imageright.style.transform = 'scale(0.7) ';
            });


            imageleft.addEventListener('mouseleave', function () {
                imageleft.style.transform = 'scale(0.7)';
                imagemiddle.style.transform = 'scale(1.0)';
                imageright.style.transform = 'scale(0.7)';
            });
        }
        if (imagemiddle) {
            imagemiddle.addEventListener('mouseenter', function () {
                imageleft.style.transform = 'scale(0.7)';
                imagemiddle.style.transform = 'scale(1.0) ';
                imageright.style.transform = 'scale(0.7) ';
            });

            imagemiddle.addEventListener('mouseleave', function () {
                imageleft.style.transform = 'scale(0.7) ';
                imagemiddle.style.transform = 'scale(1.0) ';
                imageright.style.transform = 'scale(0.7) ';
            });
        }
        if (imageright) {


            imageright.addEventListener('mouseenter', function () {
                imageleft.style.transform = 'scale(0.7) ';
                imagemiddle.style.transform = 'scale(0.7) ';
                imageright.style.transform = 'scale(1.0) ';
            });

            imageright.addEventListener('mouseleave', function () {
                imageleft.style.transform = 'scale(0.7) ';
                imagemiddle.style.transform = 'scale(1.0) ';
                imageright.style.transform = 'scale(0.7) ';
            });
        }
    });






    return (
        <div className='main-root'>
            <div className='main-header' ref={headerRef}>
                <div className='main-logo'><Link to="/"><img src={logo} width={"100px"} /></Link></div>
                <div className='main-nav'>
                    {roadmapHovered && (
                        <div className='roadmap-popup'>
                            • Own a unique NFT hero in a post-tech world & unlock their specialized skills.<br /> <br />
                            • Learn vital skills from your hero, restoring lost knowledge after a tech breakdown.<br /> <br />
                            • Connect & share with others, solving challenges together in a growing community.<br /><br />
                            • Build a sustainable future using skills & wisdom from your hero and the community.<br /><br />


                        </div>

                    )

                    }
                    <ul className='main-nav-list'><li><a onClick={scrollVision}>Vision</a></li><li><a onClick={scrollAbout}>About Us</a></li><li><a onClick={scrollRoadmap} onMouseEnter={() => setRoadmapHovered(true)} onMouseLeave={() => setRoadmapHovered(false)}>Roadmap</a></li><li><a onClick={scrollTeam}>Team</a></li><li><Link to='/Mint'>Mint</Link></li></ul></div>
                <div className='right-nav'>
                    {whitepaperHovered && (
                        <div className='whitepaper-popup'>
                            A Deep Dive Into The Mission Of Youthful Repairs’ NFT: <br /><br />

                            • Identify critical solutions we propose.<br /> <br />
                            • Understand how we address societal challenges. <br /> <br />
                            • See how unique and important our NFT-based heroic system is.<br /><br />
                            • Uncover how our NFT heroes will rebuild society after a tech collapse.<br /><br />


                        </div>

                    )

                    }
                    <ul className='main-right-nav'><li><a href="/Youthful_Repairs_Whitepaper.pdf" onMouseEnter={() => setWhitepaperHovered(true)} onMouseLeave={() => setWhitepaperHovered(false)}>Whitepaper</a></li>{account ? <a className="wallet-address">{account}</a> : <li className='last-link'> <a onClick={connectWallet} >CONNECT WALLET</a></li>}</ul></div>


            </div>
            <div className='main-banner'>
                <div className='main-content'>
                    <h2>Introduction</h2>
                    <p>The advent of Youthful Repairs represents a transformative development following a technological collapse. As modern appliances and devices ceased to function, and the population of older trade carriers dwindled, society was confronted with immense challenges. To combat this crisis, a group of heroes, who possess the essential knowledge and skills of the older generation, emerged. Each hero is represented by an NFT, symbolizing their ownership and granting unique access to their expertise, thereby forming the core of the Youthful Repairs project.
                    </p>
                    <Link to='/Mint'><a className='accent-button' >MINT </a></Link>
                </div>
            </div>
            <div className='second-section' ref={visionRef}>
                <div className='main-content-second'>
                    <h2>VISION</h2>
                    <p>The Youthful Repairs NFT acts as a key to a parallel digital universe, where trade carrier heroes' endeavor to restore modern society amidst its regressed state. Each NFT represents ownership of a hero, granting exclusive access to their specialized knowledge, skills, and trades. Blockchain technology ensures the authenticity, scarcity, and security of these digital collectibles for owners and enthusiasts alike.</p>

                </div>
            </div>
            <div className='third-section' ref={aboutRef}>
                <div className='third-sec-upper'>
                    <h2>ABOUT</h2>
                    <p> An innovative Non-Fungible Token (NFT) concept called Youthful Repairs was created as a response to a global crisis. In a terrible dystopia, modern gadgets, devices, and IoT technology stopped working, returning the globe to a prehistoric state akin to the Stone Age as the older generation of trade carriers also started steadily diminishing.  Youthful Repairers, a band of heroes with remarkable skills, are empowered through NFT ownership to save and rebuild contemporary society. Youthful Repairs seeks to restore civilization's pillars and assure humanity's prosperity through a blend of blockchain technology, community involvement, and imaginative storytelling.
                    </p>

                </div>
                <div id='imgmaindiv' className='third-sec-lower'>

                    <a id='firstimg' className='third-images left' ><img src={ntfleft} /></a>


                    <a id='secondimg' className='third-images center' ><img src={ntfcenter} /></a>


                    <a id='thirdimg' className='third-images right'><img src={ntfright} /></a>

                </div>
                {/* <a className='accent-button' href=''>EXPLORE</a> */}

            </div>

            <div className='fourth-section' ref={roadmapRef}>
                <div className='third-sec-upper'>
                    <h2>ROADMAP</h2>
                </div>
                <div className='fourth-sec-main'>

                    <div className='foruth-inner'>

                        <div className='fourth-left'>
                            {/* <h3> The Descent In To Darkness</h3> */}
                            <p> The story arc begins with a world submerging into darkness. <br />  Trade carriers, holding generations’ worth of skills, steadily begin to vanish into the darkness,<br /> and the world as we know it succumbs to the fall of technology and knowledge. </p>
                        </div>
                        <div className='fourth-right'>

                        </div>

                    </div>
                    <div className='foruth-inner'>

                        <div className='fourth-left'>

                        </div>
                        <div className='fourth-right'>
                            {/* <h3> The Descent In To Darkness</h3> */}
                            <p>As chaos ensues, the world responds by going back to Stoneage with no end in sight.<br /> Resources deplete as do does the survival of this world. <br /> The only respite that can save the people now is a miracle. </p>
                        </div>

                    </div>
                    <div className='foruth-inner'>

                        <div className='fourth-left'>
                            {/* <h3> The Descent In To Darkness</h3> */}
                            <p>Three brave warriors rise from the ashes of this dystopian Stoneage,<br /> bringing back the unique skills that were thought to be lost to the darkness.</p>
                        </div>
                        <div className='fourth-right'>

                        </div>

                    </div>
                    <div className='foruth-inner'>

                        <div className='fourth-left'>

                        </div>
                        <div className='fourth-right'>
                            {/* <h3> The Descent In To Darkness</h3> */}
                            <p>Together, our Youth Repairers <br /> begin the ascend towards rebuilding the world  <br /> through sharing knowledge and talent across the world.
                            </p>
                        </div>

                    </div>
                    <div className='roadmap'>
                        <img src={roadmap} />
                    </div>

                </div>
            </div>

            <div className='fifth-section' ref={teamRef}>
                <div className='third-sec-upper'>
                    <h2>TEAM</h2>
                </div>
                <div className='fifth-section-inner'>

                    <div className='fifth-sec-top'>
                        <a className='fifth-sec-a'><img src={ntfleft} /></a>
                        <h4>TONY</h4>
                        <h5>FOUNDER</h5>
                    </div>
                    <div className='fifth-sec-top'>
                        <a className='fifth-sec-a'><img src={ntfcenter} /></a>
                        <h4>PAM</h4>
                        <h5>EXECUTIVE ASSISTANT</h5>
                    </div>
                    {/* <div className='fifth-sec-top'>
                        <a className='fifth-sec-a'><img src={ntfright} /></a>
                        <h4>NAME</h4>
                        <h5>POSITION</h5>
                    </div> */}
                </div>


            </div>
            <div className='main-footer'>
                <div className='footer-left'>
                    <img src={logo} width={"100px"} />
                    <p>© 2024 Aroll Jones Minting</p>
                </div>
                <div className='footer-right'>

                    <ul>
                        <li><a href='https://www.instagram.com/youthfulrepairs'><img src={instagram} /></a></li>
                        <li><a href='https://discord.gg/JUC2XkdK'><img src={discord} /></a></li>
                        <li><a href='https://twitter.com/YouthfulRepairs'><img src={twitter} /></a></li>
                    </ul>
                </div>

            </div>
            <div id="backtop" className='back-to-top'>
                <img src={scrolltotop} onClick={scrollToTop} />
            </div>

            {/* {roadmapHovered && (
        <div className='roadmap-popup'>
        • Own a unique NFT hero in a post-tech world & unlock their specialized skills.<br/> <br/>
        • Learn vital skills from your hero, restoring lost knowledge after a tech breakdown.<br/> <br/>
        • Connect & share with others, solving challenges together in a growing community.<br/><br/>
        • Build a sustainable future using skills & wisdom from your hero and the community.<br/><br/>
    
    
        </div>

    )

    } */}
            {/* {whitepaperHovered && (
        <div className='whitepaper-popup'>
            A Deep Dive Into The Mission Of Youthful Repairs’ NFT: <br/><br/>

        • Identify critical solutions we propose.<br/> <br/>
        • Understand how we address societal challenges. <br/> <br/>
        • See how unique and important our NFT-based heroic system is.<br/><br/>
        • Uncover how our NFT heroes will rebuild society after a tech collapse.<br/><br/>
    
    
        </div>

    )

    } */}
















        </div>
    );

}
export default Home;