import Web3 from 'web3';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CountdownTimer from '../Assets/Components/CountdownTimer';
import '../App.css';
import logo from '../Assets/LOGOHERE.png';
import gif from '../Assets/gif.gif'
import DialogueBox from '../Assets/Components/DialogueBox';
import { ethers } from "ethers";
import abi from './ABI.json';


function MintFinal(){


  
const [isConnected, setIsConnected ] = useState (false); 
var timeNow = 0
useEffect(() => {
 timeNow = Date.now();
 console.log(timeNow)
});


const contractAddress = '0x98bdf646C02111Ec727346A79f3523d9cA941DBb'
    const [errorMessage, setErrorMessage] = useState(null);
    const web3 = new Web3(window.ethereum); 
    const [account, setAccount] = useState(localStorage.getItem('walletAddress'));
    const [minted , setMinted] = useState(false);
    
      //Connect Wallet
      const connectWallet = async () => {
        let provider = window.ethereum
        if(!provider){
          alert('Metamask not installed');
          setTimeout(() => {
            setErrorMessage(null);
          }, 2000);
        }
    else{
        provider.request({method: 'eth_requestAccounts'}).then(accounts=>{
          console.log(accounts);
          setIsConnected(true);
          // setAccount(accounts[0])
          localStorage.setItem('walletAddress', accounts[0])
          console.log(account)
        }).catch(error=>{
          console.log(error)
        })
      
       }
      }
      const discountedMint = async () => {

       

        console.log('discount mint clicked')
        var returnn = false;
        const provider = new ethers.BrowserProvider(window.ethereum)
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          abi,
          signer
          );
          contract.connect(signer);
          // const contractProvider = new ethers.Contract(
            //   contractAddress,
            //   abi,
            //   provider
            // );
            const signerAddress = await signer.getAddress();
            const balance = await provider.getBalance(signerAddress);
        
        
            // const balance_ether = ethers.utils.formatEther(balance);
        
        
            const mintPrice = '375000000000000000'
        // const balanceInEth = ethers.utils.formatEther(
        //     parseFloat(Number(mintPrice)).toString()
        // );
        let overrides = {
          value : mintPrice
          // value: ethers.utils.parseEther(balanceInEth.toString()),
        };
        
        
        //console.log("Error arha hai bois")
        const mintExec = await contract
    .discountedMint(signerAddress, overrides)
    .then((minted) => {
        console.log(minted); // Log the result of the minting process
        setMinted(true); // Set minted to true after successful minting
    })
    .catch((error) => {
        // Handle errors if any
        // Errorsetter();
        // Optionally, you can set minted to false here if needed
        // setMinted(false);
        console.error(error); // Log the error
    });

    
        // setShowSuccess(true);
        setTimeout(() => {
          // setShowSuccess(false);
        }, 5000);
      };

      function handleMint(){
       
      }


      const mintNow = async () => {

       

        console.log('mint clicked')
        var returnn = false;
        const provider = new ethers.BrowserProvider(window.ethereum)
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          abi,
          signer
          );
          contract.connect(signer);
          // const contractProvider = new ethers.Contract(
            //   contractAddress,
            //   abi,
            //   provider
            // );
            const signerAddress = await signer.getAddress();
            const balance = await provider.getBalance(signerAddress);
        
        
            // const balance_ether = ethers.utils.formatEther(balance);
        
        
            const mintPrice = '500000000000000'
        // const balanceInEth = ethers.utils.formatEther(
        //     parseFloat(Number(mintPrice)).toString()
        // );
        let overrides = {
          value : mintPrice
          // value: ethers.utils.parseEther(balanceInEth.toString()),
        };
        
        
        //console.log("Error arha hai bois")
        const mintExec = await contract
    .safeMint(signerAddress, overrides)
    .then((minted) => {
        console.log(minted); // Log the result of the minting process
        setMinted(true); // Set minted to true after successful minting
    })
    .catch((error) => {
        // Handle errors if any
        // Errorsetter();
        // Optionally, you can set minted to false here if needed
        // setMinted(false);
        console.error(error); // Log the error
    });
        // setShowSuccess(true);
        setTimeout(() => {
          // setShowSuccess(false);
        }, 5000);
      };


       
    
return(
    <div className='main-root-1'>
        <div className='main-header'>
        <div className='main-logo'><Link to = "/"><a><img src={logo} width={"100px"}/></a></Link></div>
        <div className='main-nav'><ul className='main-nav-list'><li><a href ='./' >Home</a></li> </ul></div>
        <div className='right-nav'><ul className='main-right-nav'><li><a href='https://testing.youthfulrepairs.io/Youthful_Repairs_Whitepaper.pdf'>Whitepaper</a></li>{isConnected ? <a className = "wallet-address">{account}</a> : <li className='last-link'> <a onClick={connectWallet} >CONNECT WALLET</a></li>}</ul></div>
    </div>
    <div className='mint-section'>
      <DialogueBox/>
          <img src = {gif} className='gif'/>
          {minted ? <>

            <h3>
            Congratulations on being part of the Youthful Repairs club. Your NFT has been successfully minted. <br/>
            Visit <a href='https://testnets.opensea.io/'> testnets.opensea.io </a>and connect your wallet to check your newly minted NFT.
          </h3>
          
          </> : 
          <>
           { isConnected ? <>
            <button onClick={discountedMint}>MINT</button> 
            {/* <button onClick={discountedMint}> DISCOUNTED MINT</button> */}
            </>
            : 
            <button onClick={connectWallet} >CONNECT WALLET</button>
            }
          
          </>}
          
          

    </div>
     
    </div>
);

}
export default MintFinal;