import './App.css';
import CountdownTimer from './Assets/Components/CountdownTimer';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home.js';
import Mint from './pages/Mint.js';
import ComingSoon from './pages/ComingSoon';
import MintFinal from './pages/MintFinal';





localStorage.setItem('time' ,'	1709311048000' )

function App() {
  return (

      
    <div className="App">
      <Routes>
      {/* <Route path = "/" element = {<ComingSoon/>}></Route> */}
        <Route path = "/" element = {<Home/>}></Route>
        <Route path="/mint" element={<MintFinal />}></Route>
      </Routes>
  
      <div className='Header'>
      
        
      </div>
    
    </div>
  );
}

export default App;
