import Web3 from 'web3';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CountdownTimer from '../Assets/Components/CountdownTimer';
import '../App.css';
import logo from '../Assets/LOGOHERE.png';

function Mint(){




    const [errorMessage, setErrorMessage] = useState(null);
    const web3 = new Web3(window.ethereum); 
    const [account, setAccount] = useState(localStorage.getItem('walletAddress'));
    
      //Connect Wallet
      const connectWallet = async () => {
        let provider = window.ethereum
        if(!provider){
          alert('Metamask not installed');
          setTimeout(() => {
            setErrorMessage(null);
          }, 2000);
        }
    else{
        provider.request({method: 'eth_requestAccounts'}).then(accounts=>{
          console.log(accounts);
          // setAccount(accounts[0])
          localStorage.setItem('walletAddress', accounts[0])
          console.log(account)
        }).catch(error=>{
          console.log(error)
        })
      
       }
      }
    
return(
    <div className='main-root-1'>
        <div className='main-header'>
        <div className='main-logo'><Link to = "/"><a><img src={logo} width={"100px"}/></a></Link></div>
        <div className='main-nav'><ul className='main-nav-list'><li><a href ='./' >Home</a></li> </ul></div>
        <div className='right-nav'><ul className='main-right-nav'><li><a>Whitepaper</a></li>{account ? <a className = "wallet-address">{account}</a> : <li className='last-link'> <a onClick={connectWallet} >CONNECT WALLET</a></li>}</ul></div>
    </div>
        <CountdownTimer
     countdownTimestampMs={parseInt(localStorage.getItem('time'))}/>
     
    </div>
);

}
export default Mint;