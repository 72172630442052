
import logo from '../Assets/LOGOHERE.png';
function ComingSoon(){

    return(
        <div className="main comingson">
            <img className = "coming-soon-logo"src={logo} /> 
            <h3>COMING SOON</h3>
        </div>

    );

}
export default ComingSoon